<template>
  <div class="account-login">
    <div class="qr-login" v-show="isCode">
      <p class="text">
        <span @click="changeLoginIcon('other')">其他账号登录</span>
         /
        <span @click="changeLoginIcon('acc')">账号密码登录</span>
      </p>
      <div class="login-box">
        <p class="tips">请使用手机钉钉扫码登录</p>
        <div id="login-container"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="230"
          height="224"
          viewBox="0 0 230 224"
        ></svg>
      </div>
    </div>
    <div class="acc-login" v-show="!isCode">
      <p class="text" @click="changeLoginIcon('qr')">
        <i class="qr-change"></i>
      </p>
      <div class="login-box other-box">
        <p class="tips">{{accForm.tips}}</p>
        <div id="acc-container">
          <el-form
            :model="accForm"
          >
            <el-form-item>
              <el-input v-model="accForm.account" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="accForm.password" type="password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round @click="accLogin">立即登录</el-button>
            </el-form-item>
        </el-form>
        </div>
      </div>
    </div>
    <div class="comedy-toast-box" v-show="toast.show">
      <transition name="van-slide-down">
          <div v-show="toast.show" class="comedy-toast">
            <img src="@/assets/img/error.svg" />
            <span>{{toast.msg}}</span>
          </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { loginUser, loginOther, getWhiteList, getDingID } from '@/apis/login.js'
import { setCookies } from '@/utils/auth.js'
import { useStore } from 'vuex'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { getAllUserList } from '@/apis/search.js'

export default {
  name: 'accountLogin',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    // 账号登陆信息
    const accForm = reactive({
      account: '',
      password: '',
      tips: '',
      type: 0
    })
    const redirect = computed(() => store.state.userInfo.redirect)
    // 控制切换登陆方式
    const isCode = ref(true)
    function changeLoginIcon (type) {
      if (type === 'acc') return (window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${baseurl.appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${encodeURIComponent(`${baseurl.ddurl}login`)}`)

      accForm.tips = '其他账号登录'
      accForm.type = 1
      isCode.value = !isCode.value
      accForm.account = ''
      accForm.password = ''
    }
    // 账号登录提交
    function accLogin () {
      const { account, password } = accForm
      const accLength = accForm.account.length
      const passLength = accForm.password.length
      if (accLength === 0) {
        toastCandy('请输入您的账号')
        return
      }
      if (passLength === 0) {
        toastCandy('请输入您的密码')
        return
      }
      if ((passLength > 0 && passLength < 5) || passLength > 20) {
        toastCandy('密码必须大于5位且小于20位')
        return
      }
      store.state.loading = true
      loginOther({
        account,
        password,
        login_from: 'pc'
      }).then((res) => {
        if (res.code === 0) {
          afterLogin(res.data)
        } else {
          toastCandy(res.msg)
          router.push({ path: '/login' })
          store.state.loading = false
        }
      })
    }

    // 配置二维码
    const baseurl = reactive({
      ddurl: process.env.VUE_APP_LOGIN_URL,
      appid: process.env.VUE_APP_DINGID
    })
    // loading
    function login (code) {
      if (window.navigator.userAgent.includes('DingTalk')) {
        // eslint-disable-next-line no-undef
        dd.device.base.getPhoneInfo({
          onSuccess: function (data) {
            // alert('现在是手机移动端,请前往PC端打开')
            // 打开移动端页面
            // const url = `${process.env.VUE_APP_H5_DOMAIN}?code=`
            const url = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${baseurl.appid}&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=${process.env.VUE_APP_H5_DOMAIN}`
            window.open(url)
          },
          onFail: function (err) {
            console.log(err)
          }
        })
        // eslint-disable-next-line no-undef
        if (DingTalkPC.ua.isDesktop && DingTalkPC.ua.isInDingTalk) {
          // eslint-disable-next-line no-undef
          dd.biz.util.openLink({
            url: process.env.VUE_APP_LOGIN_URL, // 要打开链接的地址
            onSuccess: function (result) {
              console.log(result)
            }
          })
        }
      } else if (code) {
        store.state.loading = true
        loginUser({
          code: code,
          login_from: 'pc'
        }).then((res) => {
          if (res.code === 0) {
            afterLogin(res.data)
          } else {
            dialogMsg('error', res.msg)
            router.push({ path: '/login' })
            store.state.loading = false
          }
        })
      }
    }

    const handleMessage = (event) => {
      const origin = event.origin
      if (origin === 'https://login.dingtalk.com' && event.data && typeof event.data !== 'object') {
        const loginTmpCode = event.data
        loginTmpCode &&
          (window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${baseurl.appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${encodeURIComponent(`${baseurl.ddurl}login`)}&loginTmpCode=${loginTmpCode}`)
      }
    }

    const afterLogin = (data) => {
      // 保存token
      setCookies('token', data.token)
      // 设置token不过期
      store.commit('userInfo/setIsExpire', false)
      dialogMsg('success', '登录成功')
      store.commit('userInfo/setUserInfo', data)
      // 获取白名单列表
      getWhiteList().then(res => {
        if (res.code === 0) {
          if (res.data.open_status === 1 && res.data.user_list) {
            // 是否白名单
            const index = res.data.user_list.indexOf(data.id.toString())
            store.commit('userInfo/setIsWhiteList', index)
          } else {
            store.commit('userInfo/setIsWhiteList', 1)
          }
        }
        store.state.loading = false
        router.push({ path: redirect.value.redirect || '/blogs/dynamics', query: redirect.value })
        store.commit('userInfo/setRedirect', {})
      })
      getAllUserList().then((res) => {
        if (res.code === 0) store.commit('userInfo/setUserMedal', res.data)
      })
    }

    // 自定义提示
    const toast = reactive({
      show: false,
      msg: '',
      switch: true
    })
    const toastCandy = (msg) => {
      if (!toast.switch) return
      toast.switch = false
      toast.show = true
      toast.msg = msg
      setTimeout(() => {
        toast.switch = true
        toast.show = false
      }, 1500)
    }

    const loginPayload = () => {
      if (!route.query.code) {
        window.DDLogin({
          id: 'login-container', // 对应刚刚的div盒子id
          goto: encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${baseurl.appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${encodeURIComponent(`${baseurl.ddurl}login`)}`),
          style: 'border:none;background-color:#FFFFFF;',
          width: 365,
          height: 360
        })
        if (typeof window.addEventListener !== 'undefined') {
          window.addEventListener('message', handleMessage, false)
        } else if (typeof window.attachEvent !== 'undefined') {
          window.attachEvent('onmessage', handleMessage)
        }
        login()
      } else {
        login(route.query.code)
      }
    }
    onMounted(() => {
      // 非正式环境动态获取dingID
      if (!['pro', 'production'].includes(process.env.VUE_APP_NODE_ENV)) {
        getDingID().then(({ data }) => {
          baseurl.appid = data.app_key
          loginPayload()
        })
      } else {
        loginPayload()
      }
    })

    return {
      isCode,
      accForm,
      changeLoginIcon,
      accLogin,
      toast
    }
  }
}
</script>

<style lang="less">
.account-login {
  position: relative;
  padding: 24px;
  background: @colorWhite;
  color: #000;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  .text {
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 35px;
    text-align: right;
    position: relative;
  }
  .text{
    span{
      transition: all 0.3s;
    }
    span:hover{
      color: #7972F0;
    }
  }
  .login-box{
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tips {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
    }
  }
  .acc-login{
    width: 465px;
    .qr-change{
      background: url('../../../assets/img/qrChange-new.svg') center;
      width: 60px;
      height: 60px;
      display: inline-block;
      background-size: 142px;
      background-position: -72px -7px;
      background-repeat: no-repeat;
      position: absolute;
      top: -52px;
      right: -16px;
    }
    .other-box{
      padding: 60px 50px 0 50px;
    }
    #acc-container{
      padding-top: 20px;
      width: 306px;
      .el-input__inner{
        border-radius: 100px;
        background: #F4F4F4;
        border: 1px solid #F4F4F4;
        height: 44px;
      }
      .el-button{
        width: 100%;
        height: 44px;
      }
    }
  }
}
.comedy-toast-box{
  position: absolute;
  top: 70px;
  margin: auto;
  left: 0;
  right: 0;
  .comedy-toast {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    background: #FFFFFF;
    color: #FF3D3D;
    font-size: 14px;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 52px;
    margin: auto;
    height: 42px;
    top: 0;
    span{
      padding-left: 5px;
    }
  }
}
</style>
